import React from 'react'
import './Navbar.css'
import { AnimatePresence, motion } from "framer-motion"

import { useState } from 'react';
// import 'src\Images\test1.svg'


const Navbar = () => {
  const [hamOpen, setHamOpen] = useState(false)
  function openMenu() {
    document.getElementById("hamMeny").classList.toggle('active')
    // document.getElementById("dropdown").classList.toggle('active')
    setHamOpen(!hamOpen)
  }

  return (
    <nav className="navbar" >
            <a href="/"><h2>TikzMaker</h2></a>
            <div className='version'>
              <p style={{margin:"0",padding: "0" }}>Beta 3.3</p> 
              </div> 
            <div className="links"> 
                <a href="/editor" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "editor" ? "active-navbar-btn" : "navbar-btn"}>Editor</a>
                <a href="/updates" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "updates" ? "active-navbar-btn" : "navbar-btn"}>Updates</a>
                <a href="/bugs" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "bugs" ? "active-navbar-btn" : "navbar-btn"}>Known bugs</a>
                <a href="/contact" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "contact" ? "active-navbar-btn" : "navbar-btn"}>Contact</a>
                <a href="/tutorial" style = {{marginRight: "100px"}} className={window.location.href.split("/")[window.location.href.split("/").length-1] === "tutorial" ? "active-navbar-btn" : "navbar-btn"}>Tutorial</a>
            </div>

            <div className="links-mobile" id="links-mobile">
                <svg className="ham hamRotate ham4" id="hamMeny" viewBox="0 0 100 100" onClick={()=> openMenu()}>
              <path className="line top" d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
              <path className="line middle" d="m 70,50 h -40" />
              <path className="line bottom" d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
            </svg>
            

            </div>
              <AnimatePresence>
                {hamOpen && (<motion.div 
                  initial = {{  height: 0}}
                  animate={{  height: 350}}
                  transition={{ duration: 0.3, delayChildren: 5}}
                  exit={{  height: 0}}
                  className='dropdown' 
                  // id='dropdown'
                  style={{zIndex: "2"}}>
                  <motion.div 
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{  duration: 0.15}}
                  exit={{ opacity: 0}}


                  className="links2">
                      <a href="/" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "" ? "active-navbar-btn" : "navbar-btn"}>Home</a>
                      <a href="/editor" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "editor" ? "active-navbar-btn" : "navbar-btn"}>Editor</a>
                      <a href="/updates" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "updates" ? "active-navbar-btn" : "navbar-btn"}>Updates</a>
                      <a href="/bugs" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "bugs" ? "active-navbar-btn" : "navbar-btn"}>Known bugs</a>
                      <a href="/contact" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "contact" ? "active-navbar-btn" : "navbar-btn"}>Contact</a>
                      <a href="/tutorial" className={window.location.href.split("/")[window.location.href.split("/").length-1] === "tutorial" ? "active-navbar-btn" : "navbar-btn"}>Tutorial</a>
                  </motion.div>
                </motion.div>)}
              </AnimatePresence>
        </nav>
  )
};


export default Navbar;
