
import React, { useEffect, useState } from 'react'
import './CookieBanner.css'


function CookieBanner() {
    const [show, setShow] = useState(false)
    useEffect(() => {
        const cookies = JSON.parse(window.localStorage.getItem("Accepted-Cookies"))
        if (cookies == null) {
            window.localStorage.setItem("Accepted-Cookies", JSON.stringify({Accepted: false, timestamp: Date.now()}))
            setShow(true)
        }
        else if (cookies.Accepted) {
            window.localStorage.setItem("Accepted-Cookies", JSON.stringify({Accepted: true, timestamp: Date.now()}))
            setShow(false)
        }
        else {
            window.localStorage.setItem("Accepted-Cookies", JSON.stringify({Accepted: false, timestamp: Date.now()}))
            setShow(true)
        }
        
    }, [])
    

    function Answear(a) {
        setShow(false)
        if (a) {
            window.localStorage.setItem("Accepted-Cookies", JSON.stringify({Accepted: true, timestamp: Date.now()}))
        } else {
            window.localStorage.setItem("Accepted-Cookies", JSON.stringify({Accepted: false, timestamp: Date.now()}))
        }
    }

  return (
    <>
        {show && <div className='banner'>
            <p className='text'>We use cookies to know how many visitors we get. We do not store nor do we sell the cookies to third parties.</p>
            <button className='button' onClick={() => Answear(true)} style={{background: "#ade8f4"}}   >Accept</button>
            <button className='button' onClick={() => Answear(false)}>Decline</button>
        </div>
    }
    </>

  )
}

export default CookieBanner