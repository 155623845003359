import React, { Suspense } from 'react';
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom'

import Navbar from './Navbar/Navbar';
import './App.css';
import LandingPage from './LandingPage';


const LayzyTest = React.lazy(() => import("./Test"))
const LayzyContact = React.lazy(() => import("./Navbar/Pages/Contact"))
const LayzyGlitches = React.lazy(() => import("./Navbar/Pages/Glitches"))
const LayzyUpdates = React.lazy(() => import("./Navbar/Pages/Updates"))
const LayzyTutorial = React.lazy(() => import("./Navbar/Pages/Tutorial"))
const LayzyExamples= React.lazy(() => import("./Navbar/Pages/Examples"))
const LayzyPrivacy= React.lazy(() => import("./Navbar/Pages/Privacy"))
const LayzyAdmin = React.lazy(() => import("./Navbar/Pages/Admin"))

function App() {
  return (
    <div className="App">
      <Navbar />
      <div>
        <Router>
            <Routes>
              <Route exact path="/" element={<Suspense fallback={<div>Loading...</div>}><LandingPage key="LandingPage"/></Suspense>}/> 
              <Route path="/examples" element={<Suspense fallback={<div>Loading...</div>}><LayzyExamples/></Suspense>} key="Examples"/>
              <Route path="/editor/:data" element={<Suspense fallback={<div>Loading...</div>}><LayzyTest/></Suspense>} key="Editor"/>
              <Route path="/editor" element={<Suspense fallback={<div>Loading...</div>}><LayzyTest/></Suspense>} key="Editor"/>
              <Route path="/tutorial" element={<Suspense fallback={<div>Loading...</div>}><LayzyTutorial /></Suspense>} key="Tutorial"/>
              <Route path="/contact" element={<Suspense fallback={<div>Loading...</div>}><LayzyContact /></Suspense>} key="Contact"/>
              <Route path="/bugs" element={<Suspense fallback={<div>Loading...</div>}><LayzyGlitches/></Suspense>} key="Bugs"/>
              <Route path="/updates" element={<Suspense fallback={<div>Loading...</div>}><LayzyUpdates/></Suspense>} key="Updates"/>
              <Route path="/privacy" element={<Suspense fallback={<div>Loading...</div>}><LayzyPrivacy/></Suspense>} key="Privacy"/>
              <Route path="/admin" element={<Suspense fallback={<div>Loading...</div>}><LayzyAdmin/></Suspense>} key="Admin"/>
              
            </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
