import './LandingPage.css';
import { motion } from "framer-motion"


import exampleFront1 from './Images/EksampleFront.webp'
import exampleFront2 from './Images/EksampleFront2.webp'
import exampleFront3 from './Images/EksampleFront3.webp'


import example1 from './Images/eksempel1.webp'
import example2 from './Images/eksempel2.webp'
import example3 from './Images/eksempel3.webp'
import example4 from './Images/eksempel4.webp'

import elsys from './Images/elsys.webp'
import ntnuLogo from './Images/ntnuLogo.webp'
import CoockieBanner from "./CookieBanner";

import { AiOutlineInstagram } from 'react-icons/ai'
import { IoLogoTwitter } from 'react-icons/io'




function LandingPage() {
    function Chart(props) {
    return (
      <div className="single-chart">
          <svg viewBox="0 0 36 36" className="circular-chart orange">
              <path className="circle-bg"
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path className="circle"
              strokeDasharray= {props.percent + ", 100"}
              d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="20.35" className="percentage">{props.percent}%</text>
          </svg>
      </div>
    )
}


  return (
    <div className="App2">
      <CoockieBanner/>    
      <div className='main'>
          <div className='headline'>
            <motion.h2 initial={{opacity: 0, y: -30}} animate={{opacity: 1, y: 0}} transition={{delay:0.1, duration: 1}} style={{width: "100%"}}>TikzMaker</motion.h2>
            <h1 style={{fontWeight: 200}}><span className='TextUnderline'>Free</span> online Tikz editor to make <span className='TextUnderline'>Beautiful</span> Latex figures in <span className='TextUnderline'>Minutes!</span></h1>
            <div style={{display: "flex", justifyContent: "center", alignItems:"center", marginTop: "50px"}}>
              <button className="btn"><a href="/editor">Get started</a></button>
            </div>
        </div>

        <div className='cards'>
          <div className='glass card' style={{zIndex: 5, mixBlendMode: "multiply"}}>
            Example 1
            <img loading='lazy' src={exampleFront1} style={{width: "80%", height: "80%"}} alt="Example 1"/>
            </div>
          <div className='glass card' style={{zIndex: 4, mixBlendMode: "multiply"}}>
          Example 2
          <img loading='lazy' src={exampleFront2} style={{width: "80%", height: "90%"}} alt="Example 2"/>
          </div>
          <div className='glass card' style={{zIndex: 3, mixBlendMode: "multiply"}}>
          Example 3
          <img loading='lazy' src={exampleFront3} style={{width: "80%", height: "80%"}} alt="Example 3"/>
          </div>
        </div>

      </div>
      <div className='circleTop'/>
      <div className='circleBg'/>
      <div className='circle2'/>
      <motion.div
      initial={{ opacity: 0, scale: 1.2}}
      whileInView={{ opacity: 1, scale: 1}}
      transition={{ delay: 0.7 }}
      viewport={{ once: true }}
       className='testemonialsMobile'>
      <div className='testemonialMobile'>
                  <img loading='lazy' src={ntnuLogo} alt="NTNU logo"/>
                  <p>Used at the largest university in Norway NTNU (Norwegian University of Science and Technology)</p>
           
        </div>
        <div className='testemonialMobile'>
            <Chart percent={95}/>
            <p className='textTestemonial'>Spend 95% less time writing code for your Latex figures</p>
        </div>
        <div className='testemonialMobile'>
            <div style={{mixBlendMode: "multiply"}}>
                <img loading='lazy' src= {elsys} alt="elsys logo"/>
            </div>
            <p>Widely used by students and educators in the masterprogram ELSYS to further develop the educational material</p>

        </div>
      </motion.div>
      <div className='testemonials'>
        <motion.div 
          initial={{ opacity: 0, x: -100}}
          whileInView={{ opacity: 1, x: 0}}
          transition={{ delay: 0.5 }}
          viewport={{ once: true }}
          className="testemonial">
            <img loading='lazy' src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/NTNU-logo.svg/2560px-NTNU-logo.svg.png' alt="NTNU logo"/>
           <p>Used at the largest university in norway NTNU (Norwegian University of Science and Technology)</p>

        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 1.2}}
          whileInView={{ opacity: 1, scale: 1}}
          transition={{ delay: 0.7 }}
          viewport={{ once: true }}
          className="testemonial middle">
            <Chart percent={95}/>
            <p className='textTestemonial'>Spend 95% less time writing code for your Latex figures</p>
        </motion.div>
        <motion.div 
        viewport={{ once: true }}
        initial={{ opacity: 0, x: 100}}
        whileInView={{ opacity: 1, x: 0}}
        transition={{ delay: 0.5 }}
        className="testemonial"
        >
            <div style={{mixBlendMode: "multiply"}} className='elsysLogo'>
                <img loading='lazy' src= {elsys} alt="elsys logo"/>
            </div>
            <p>Widely used by students and educators in the masterprogram ELSYS to further develop the educational material</p>
        </motion.div>
      </div>
      <h2><span className='TextUnderline h3'>More examples</span></h2>
      <motion.div 
        initial={{ opacity: 0, scale: 1.2}}
        whileInView={{ opacity: 1, scale: 1}}
        transition={{ delay: 0.7 }}
        viewport={{ once: true }}
        className='moreExamples'>
        <div className="ExampleCard">
          <p> 1 - Continuity equation</p>
          <img loading='lazy' src={example1} style={{width: "80%"}} alt="Example 1"/>
          <p style={{color: "gray"}}><a href="/examples">See more examples</a></p>
        </div>
        <div className="ExampleCard">
          <p> 2 - Bit cell</p>
          <img loading='lazy' src={example2} style={{width: "80%"}} alt="Example 2"/>
          <p style={{color: "gray"}}><a href="/examples">See more examples</a></p>
        </div>
        <div className="ExampleCard">
          <p> 3 - Decoder</p>
          <img loading='lazy' src={example3} style={{width: "80%"}} alt="Example 3"/>
          <p style={{color: "gray"}}><a href="/examples">See more examples</a></p>
        </div>
        <div className="ExampleCard">
          <p> 4 - Schematic for a bike counter</p>
          <img loading='lazy' src={example4} style={{width: "80%"}} alt="Example 4"/>
          <p style={{color: "gray"}}><a href="/examples">See more examples</a></p>
        </div>
      </motion.div>

      <button className="btn" style={{marginTop: "80px"}}><a href="/editor">Try Free now!</a></button>


      <div className='footer'>
        <div>
        <div>
          <p><a href="/contact">Contact Me</a></p>
          <p style={{margin: "0px", padding: "0px"}}><a href="/privacy">Privacy Policy</a></p>
        </div>
        <div>
          <p>© 2021-{new Date().getFullYear()} TikzMaker</p>
        </div>
        </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <ul style={{textAlign: "left"}}>
              <li><a href="/">Landing Page</a></li>
              <li><a href="/editor">Editor</a></li>
              <li><a href="/updates">Updates</a></li>
            </ul>
            <ul style={{textAlign: "left"}}>
              <li><a href="/bugs">Known bugs</a></li>
              <li><a href="/Contact">Contact</a></li>
              <li><a href="/tutorial">Tutorial</a></li>
            </ul>
            </div>
            <div style={{display: 'grid', marginLeft: "20px"}}>
              <a href="https://www.instagram.com/tikzmaker/" aria-label="Link to our Instagram"><AiOutlineInstagram size={30}/></a>
              <a href="https://twitter.com/TikzMaker" aria-label="Link to our Twitter"><IoLogoTwitter size={30}/></a>
              
              
            </div>
      </div>
  </div>
  );
}

export default LandingPage;
